.OperationManagementSection {
    .contents {
        .headerComponent {
            background-color: $Color_Byad;
            position: relative;
            padding: 2% 2%;
            font-family: $main_font;
            .contents {
                display: flex;
                justify-content: left;
                align-items: baseline;
                .rowContents {
                    width: 16%;
                    margin-right: 1%;
                    &:last-of-type {
                        width: 30%;
                        display: flex;
                        justify-content: right;
                    }
                    p {
                        color: $mainColor;
                        font-weight: 600;
                    }
                    .submitButton {
                        background-color: $mainColor;
                        border: unset;
                        font-size: 0.8rem;
                    }
                }
            }
        }
        .leftSideTabContent {
            padding: 1% 3%;
            background-color: #F3F3F3;
            height: 65vh;
            overflow-y: scroll;

            .cardStyle {
                border: unset;
                width: 100%;
                transition: all 0.5s ease-in-out;
                &:hover {
                    background: linear-gradient(79deg, rgba(243,243,243,1) 80%, rgba(28,188,180,1) 100%);
                    @include gradient-animation
                }
                .fromTo {
                    font-family: $main_font;
                    position: relative;
                    &::before {
                        content: '';
                        position: absolute;
                        left: 4px;
                        top: -4px;
                        width: 2px;
                        height: 10px;
                        background-color: $mainColor;
                        height: 90%;
                        border-radius: 100px;
                    }
                    p {
                        color: $Color_Gri;
                        margin: 0;
                        position: relative;
                        padding-left: 10%;
                        b {
                            color: $Color_K7al;
                        }
                        &::before {
                            content: '';
                            position: absolute;
                            left: 0px;
                            top: 20%;
                            width: 10px;
                            height: 10px;
                            background-color: $mainColor;
                            border-radius: 50%;
                        }
                    }
                    small {
                        color: $Color_Gri;
                        padding-left: 10%;
                    }
                    .space {
                        margin-bottom: 4rem;
                    }
                }
                .modeleAndConductor {
                    h3 {
                        color: $mainColor;
                        font-size: 1rem;
                        margin: 0;
                        span {
                            color: $Color_K7al;
                        }
                    }
                    .space {
                        margin-bottom: 3.5rem;
                    }
                    h4 {
                        color: $mainColor;
                        font-size: 1rem;
                        margin: 0;
                        span {
                            color: $Color_K7al;
                        }
                    }
                    p {
                        margin: 0;
                        position: relative;
                        padding-left: 8%;
                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 5px;
                            width: 10px;
                            height: 10px;
                            background-color: $mainColor;
                            border-radius: 50%;
                        }
                    }
                }
                .handleOrderMission {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    p {
                        margin: 0;
                        font-weight: bold;
                        font-size: 1.2rem;
                    }
                    img {
                        width: 50%;
                    }
                    Button {
                        &:first-of-type {
                            background-color: $mainColor;
                            border: unset;
                            font-size: 0.8rem;
                            margin-bottom: 4%;
                            margin-top: 3%;
                            width: 70%;
                        }
                        &:last-of-type {
                            background-color: $Color_Limoni;
                            border: unset;
                            font-size: 0.8rem;
                            width: 70%;
                        }
                    }
                }
            }
        }
        .rightSideTabContent {

        }
    }
}
.NewOperationManagementSection {
    .leftSideContents {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100vh;
        .rowContents {
            display: flex;
            justify-content: space-around;
            margin-top: 3%;
            label {
                color: $mainColor;
                font-weight: 500;
            }
            .w-80 {
                width: 80%;
            }
            .w-10 {
                width: 10%;
            }
            .ant-form-item {
                width: 45%;
            }
            input, .ant-select {
                width: 100%;
            }
            p {
                margin: 0;
            }
            .submitBtn {
                background-color: $mainColor;
                border: unset;
                width: 35%;
            }
        }
    }
}