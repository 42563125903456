@mixin X_Small {
    @media (min-width: 576px) { 
        @content;
    }
}

@mixin Small {
    @media (max-width: 576px) { 
        @content;
    }
}

@mixin Medium {
    @media (max-width: 768px) { 
        @content;
    }
}

@mixin Large {
    @media (max-width: 992px) { 
        @content;
    }
}

@mixin Extra_large {
    @media (max-width: 1200px) { 
        @content;
    }
}

@mixin Extra_extra_large {
    @media (max-width: 1400px) { 
        @content;
    }
}

@mixin Extra_extra_extra_large1 {
    @media (max-width: 1500px) { 
        @content;
    }
}

@mixin Extra_extra_extra_large {
    @media (max-width: 1600px) { 
        @content;
    }
}
