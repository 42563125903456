$Color_Zra9: #5e72e4;
$Color_Zra9_2: #172b4d;
$Color_K7al: #0C0000;
$Color_Chibi: #11cdef;
$Color_Khdar: #2dce89;
$Color_7mar: #f5365c;
$Color_Limoni: #fb6340;
$Color_Gri: #6c757d;
$Color_Byad: #f8f9fa;

$mainColor: #1cbcb4;
$mainColor2: #3A7E8B; 

$main_font: 'Poppins', sans-serif;
