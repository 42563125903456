.itemsManagementSection {
    .contents {
        .headerComponent {
            .contents {
                .rowContents {
                    background-color: $Color_Byad;
                    position: relative;
                    padding: 1% 8%;
                    font-family: $main_font;
                    display: flex;
                    justify-content: space-between;
                    font-family: $main_font;
                    align-items: baseline;
                    p {
                        color: $mainColor;
                        font-weight: 500;
                    }
                    button {
                        background-color: $mainColor;
                        border: unset;
                    }
                }
            }
        }
        .table {
            padding: 4% 2%;
            table {
                thead {
                    tr {
                        th {
                            background-color: $mainColor;
                            border-color: $mainColor;
                            color: $Color_Byad;
                            text-align: center;
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            border: unset;
                            text-align: center;
                            svg {
                                cursor: pointer;
                            }
                            .btnStatus {
                                display: flex;
                                justify-content: space-around;
                                button {
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.NewItemsManagementSection {
    .contents {
        .topInput {
            display: flex;
            justify-content: space-around;
            padding-top: 2%;
            .input {
                width: 20%;
                .ant-picker {
                    width: 100%;
                }
            }
        }
        .bodyInput {
            display: flex;
            .left {
                width: 60%;
                font-family: $main_font;
                h3 {
                    color: $mainColor;
                    text-align: center;
                    font-weight: 500;
                    font-size: 1.5rem;
                }
                .left_side_input {
                    margin-bottom: 2%;
                    display: flex;
                    justify-content: center;
                    input {
                        width: 60%;
                    }
                }
            }
            .right {
                width: 40%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: $main_font;
                h3 {
                    color: $mainColor;
                    text-align: center;
                    font-weight: 500;
                    font-size: 1.5rem;
                }
                p {
                    font-weight: bold;
                }
            }
        }
        .submitBtn {
            display: flex;
            justify-content: center;
            button {
                background-color: $mainColor;
                border: unset;
                width: 30%;
            }
        }
    }
}
