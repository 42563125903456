@mixin dataManagementHederStyle {
    .contents {
        .rowContents {
            background-color: $Color_Byad;
            position: relative;
            padding: 1% 8%;
            font-family: $main_font;
            display: flex;
            justify-content: space-between;
            font-family: $main_font;
            align-items: baseline;
            p {
                color: $mainColor;
                font-weight: 500;
            }
            button {
                background-color: $mainColor;
                border: unset;
            }
        }
    }
}

@mixin dataManagementTableStyle {
    padding: 4% 2%;
    table {
        thead {
            tr {
                th {
                    background-color: $mainColor;
                    border-color: $mainColor;
                    color: $Color_Byad;
                    text-align: center;
                }
            }
        }
        tbody {
            tr {
                td {
                    border: unset;
                    text-align: center;
                    width: 100px;
                    svg {
                        cursor: pointer;
                    }
                    .btnStatus {
                        display: flex;
                        justify-content: space-around;
                        button {
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}

@mixin newOperationStyle {
    font-size: $main_font;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .inputContents {
        input, .ant-picker, .ant-select {
            margin-bottom: 2%;
            width: 100%;
        }
    }
    .submitBtn {
        display: flex;
        justify-content: center;
        button {
            background-color: $mainColor;
            border: unset;
            width: 30%;
        }
    }
}

@mixin gradient-animation {
    background-size: 200% 200%;
    animation: gradientAnimation 1s ease forwards; // infinite
}
@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 50% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}
