.missionOrder {
    .contents {
        .bottomBtn {
            display: flex;
            justify-content: center;
            width: 100%;
            align-items: center;
            button {
                background-color: $mainColor;
                border: unset;
                &:hover {
                    background-color: rgba($mainColor, .5);
                }
            }
        }
        .righSideContents {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            font-family: $main_font;
            p {
                margin: 0;
                text-align: center;
                color: $Color_Gri;
                font-weight: 500;
            }
            .logo {
                width: 60%;
            }
        }
    }
    .missionOrderContents {
        font-family: $main_font;
        display: flex;
        height: 80vh;
        width: 100%;
        justify-content: center;
        align-items: center;
        .ant-card {
            width: 100%;
        }
        h2 {
            color: #E0A528;
            text-align: center;
        }
        .contents {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                top: 4%;
                left: 2%;
                width: 2px;
                height: 85%;
                background-color: $mainColor;
            }
            .left {
                padding-left: 4%;
                width: 70%;
                h3 {
                    margin: 0;
                    color: $mainColor;
                    position: relative;
                    &::before {
                        content: "";
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        left: -4%;
                        top: 40%;
                        background-color: $mainColor;
                        border-radius: 50%;
                    }
                }
                small {
                    color: $Color_Gri;
                    font-size: .8rem !important;
                    font-weight: bold;
                }
                h4 {
                    margin: 0;
                    color: #3A7E8B;
                    font-weight: 700;
                    font-size: 1rem;
                    margin-left: 2%;
                }
                p {
                    color: $Color_Gri;
                    font-weight: bold;
                    border: 2px solid $Color_Gri;
                    width: 80%;
                    text-align: center;
                    border-radius: 5px;
                    margin-left: 2%;
                }
                .dateMission {
                    display: flex;
                    justify-content: space-between;
                    width: 80%;
                    margin-left: 2%;
                    p {
                        width: 80%;
                    }
                }
            }
            .right {
                width: 30%;
                h3 {
                    color: $mainColor;
                    margin: 0;
                }
                small {
                    color: $Color_Gri;
                    font-weight: bold;
                    font-size: 1rem;
                }
                h4 {
                    color: $Color_Gri;
                    font-size: 1rem;
                    position: relative;
                    padding-left: 5%;
                    &::before {
                        content: "";
                        background-color: #CFCFCF;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        position: absolute;
                        left: 0;
                        top: 30%;
                    }
                }
            }
        }
    }
}
