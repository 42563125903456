.evaluationSection {
    .headerComponent {
        @include dataManagementHederStyle;
        .contents {
            display: block;
        }
    }
    .topInputs {
        display: flex;
        justify-content: space-around;
        font-family: $main_font;
        margin-top: 2%;
        .inputs {
            width: 22%;
            label {
                font-size: .8rem;
                text-align: center;
                color: $mainColor;
                font-weight: 500;
            }
        }
    }
    .evaluationSectionContents {
        margin-top: 4%;
        .leftContents {
            font-family: $main_font;
            width: 100%;
            margin-top: 8%;
            h3 {
                color: $mainColor2;
                font-weight: 500;
                font-size: 1.5rem;
                position: relative;
                margin-left: 10%;
                &::before {
                    content: '';
                    position: absolute;
                    width: 4px;
                    height: 100%;
                    background-color: $mainColor2;
                    left: -3%;
                    top: 0;
                }
            }
            .cardContents {
                p {
                    display: grid;
                    grid-template-columns: 2fr 0.5fr 1.5fr 0.5fr;
                    span {
                        color: $mainColor;
                        &:first-of-type {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}
