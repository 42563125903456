@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
.row {
  margin: 0;
  padding: 0;
}

.login_section {
  background-image: url("./../img/loginBack.jpg");
  background-position: center;
  background-size: cover;
  height: 100vh;
}
.login_section .contents {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  font-family: "Poppins", sans-serif;
}
.login_section .contents img {
  width: 8%;
}
.login_section .contents h1 {
  text-align: center;
  color: #6c757d;
  font-size: 1rem;
}
.login_section .contents h2 {
  text-align: center;
  color: #1cbcb4;
  font-size: 1.2rem;
  font-weight: 600;
}
@media (max-width: 992px) {
  .login_section .contents h2 {
    margin-top: 5rem;
  }
}
.login_section .contents form {
  width: 60%;
}
@media (max-width: 992px) {
  .login_section .contents form {
    width: 80%;
  }
}
.login_section .contents form .submitButton {
  background-color: #1cbcb4;
  border: unset;
  width: 30%;
  margin-left: 35%;
  margin-right: auto;
}
@media (max-width: 992px) {
  .login_section .contents form .submitButton {
    width: 100%;
    margin: 0;
  }
}

.OperationManagementSection .contents .headerComponent {
  background-color: #f8f9fa;
  position: relative;
  padding: 2% 2%;
  font-family: "Poppins", sans-serif;
}
.OperationManagementSection .contents .headerComponent .contents {
  display: flex;
  justify-content: left;
  align-items: baseline;
}
.OperationManagementSection .contents .headerComponent .contents .rowContents {
  width: 16%;
  margin-right: 1%;
}
.OperationManagementSection .contents .headerComponent .contents .rowContents:last-of-type {
  width: 30%;
  display: flex;
  justify-content: right;
}
.OperationManagementSection .contents .headerComponent .contents .rowContents p {
  color: #1cbcb4;
  font-weight: 600;
}
.OperationManagementSection .contents .headerComponent .contents .rowContents .submitButton {
  background-color: #1cbcb4;
  border: unset;
  font-size: 0.8rem;
}
.OperationManagementSection .contents .leftSideTabContent {
  padding: 1% 3%;
  background-color: #F3F3F3;
  height: 65vh;
  overflow-y: scroll;
}
.OperationManagementSection .contents .leftSideTabContent .cardStyle {
  border: unset;
  width: 100%;
  transition: all 0.5s ease-in-out;
}
.OperationManagementSection .contents .leftSideTabContent .cardStyle:hover {
  background: linear-gradient(79deg, rgb(243, 243, 243) 80%, rgb(28, 188, 180) 100%);
  background-size: 200% 200%;
  animation: gradientAnimation 1s ease forwards;
}
.OperationManagementSection .contents .leftSideTabContent .cardStyle .fromTo {
  font-family: "Poppins", sans-serif;
  position: relative;
}
.OperationManagementSection .contents .leftSideTabContent .cardStyle .fromTo::before {
  content: "";
  position: absolute;
  left: 4px;
  top: -4px;
  width: 2px;
  height: 10px;
  background-color: #1cbcb4;
  height: 90%;
  border-radius: 100px;
}
.OperationManagementSection .contents .leftSideTabContent .cardStyle .fromTo p {
  color: #6c757d;
  margin: 0;
  position: relative;
  padding-left: 10%;
}
.OperationManagementSection .contents .leftSideTabContent .cardStyle .fromTo p b {
  color: #0C0000;
}
.OperationManagementSection .contents .leftSideTabContent .cardStyle .fromTo p::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 20%;
  width: 10px;
  height: 10px;
  background-color: #1cbcb4;
  border-radius: 50%;
}
.OperationManagementSection .contents .leftSideTabContent .cardStyle .fromTo small {
  color: #6c757d;
  padding-left: 10%;
}
.OperationManagementSection .contents .leftSideTabContent .cardStyle .fromTo .space {
  margin-bottom: 4rem;
}
.OperationManagementSection .contents .leftSideTabContent .cardStyle .modeleAndConductor h3 {
  color: #1cbcb4;
  font-size: 1rem;
  margin: 0;
}
.OperationManagementSection .contents .leftSideTabContent .cardStyle .modeleAndConductor h3 span {
  color: #0C0000;
}
.OperationManagementSection .contents .leftSideTabContent .cardStyle .modeleAndConductor .space {
  margin-bottom: 3.5rem;
}
.OperationManagementSection .contents .leftSideTabContent .cardStyle .modeleAndConductor h4 {
  color: #1cbcb4;
  font-size: 1rem;
  margin: 0;
}
.OperationManagementSection .contents .leftSideTabContent .cardStyle .modeleAndConductor h4 span {
  color: #0C0000;
}
.OperationManagementSection .contents .leftSideTabContent .cardStyle .modeleAndConductor p {
  margin: 0;
  position: relative;
  padding-left: 8%;
}
.OperationManagementSection .contents .leftSideTabContent .cardStyle .modeleAndConductor p::before {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  width: 10px;
  height: 10px;
  background-color: #1cbcb4;
  border-radius: 50%;
}
.OperationManagementSection .contents .leftSideTabContent .cardStyle .handleOrderMission {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.OperationManagementSection .contents .leftSideTabContent .cardStyle .handleOrderMission p {
  margin: 0;
  font-weight: bold;
  font-size: 1.2rem;
}
.OperationManagementSection .contents .leftSideTabContent .cardStyle .handleOrderMission img {
  width: 50%;
}
.OperationManagementSection .contents .leftSideTabContent .cardStyle .handleOrderMission Button:first-of-type {
  background-color: #1cbcb4;
  border: unset;
  font-size: 0.8rem;
  margin-bottom: 4%;
  margin-top: 3%;
  width: 70%;
}
.OperationManagementSection .contents .leftSideTabContent .cardStyle .handleOrderMission Button:last-of-type {
  background-color: #fb6340;
  border: unset;
  font-size: 0.8rem;
  width: 70%;
}
.NewOperationManagementSection .leftSideContents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
.NewOperationManagementSection .leftSideContents .rowContents {
  display: flex;
  justify-content: space-around;
  margin-top: 3%;
}
.NewOperationManagementSection .leftSideContents .rowContents label {
  color: #1cbcb4;
  font-weight: 500;
}
.NewOperationManagementSection .leftSideContents .rowContents .w-80 {
  width: 80%;
}
.NewOperationManagementSection .leftSideContents .rowContents .w-10 {
  width: 10%;
}
.NewOperationManagementSection .leftSideContents .rowContents .ant-form-item {
  width: 45%;
}
.NewOperationManagementSection .leftSideContents .rowContents input, .NewOperationManagementSection .leftSideContents .rowContents .ant-select {
  width: 100%;
}
.NewOperationManagementSection .leftSideContents .rowContents p {
  margin: 0;
}
.NewOperationManagementSection .leftSideContents .rowContents .submitBtn {
  background-color: #1cbcb4;
  border: unset;
  width: 35%;
}

.missionOrder .contents .bottomBtn {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}
.missionOrder .contents .bottomBtn button {
  background-color: #1cbcb4;
  border: unset;
}
.missionOrder .contents .bottomBtn button:hover {
  background-color: rgba(28, 188, 180, 0.5);
}
.missionOrder .contents .righSideContents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-family: "Poppins", sans-serif;
}
.missionOrder .contents .righSideContents p {
  margin: 0;
  text-align: center;
  color: #6c757d;
  font-weight: 500;
}
.missionOrder .contents .righSideContents .logo {
  width: 60%;
}
.missionOrder .missionOrderContents {
  font-family: "Poppins", sans-serif;
  display: flex;
  height: 80vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.missionOrder .missionOrderContents .ant-card {
  width: 100%;
}
.missionOrder .missionOrderContents h2 {
  color: #E0A528;
  text-align: center;
}
.missionOrder .missionOrderContents .contents {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.missionOrder .missionOrderContents .contents::before {
  content: "";
  position: absolute;
  top: 4%;
  left: 2%;
  width: 2px;
  height: 85%;
  background-color: #1cbcb4;
}
.missionOrder .missionOrderContents .contents .left {
  padding-left: 4%;
  width: 70%;
}
.missionOrder .missionOrderContents .contents .left h3 {
  margin: 0;
  color: #1cbcb4;
  position: relative;
}
.missionOrder .missionOrderContents .contents .left h3::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  left: -4%;
  top: 40%;
  background-color: #1cbcb4;
  border-radius: 50%;
}
.missionOrder .missionOrderContents .contents .left small {
  color: #6c757d;
  font-size: 0.8rem !important;
  font-weight: bold;
}
.missionOrder .missionOrderContents .contents .left h4 {
  margin: 0;
  color: #3A7E8B;
  font-weight: 700;
  font-size: 1rem;
  margin-left: 2%;
}
.missionOrder .missionOrderContents .contents .left p {
  color: #6c757d;
  font-weight: bold;
  border: 2px solid #6c757d;
  width: 80%;
  text-align: center;
  border-radius: 5px;
  margin-left: 2%;
}
.missionOrder .missionOrderContents .contents .left .dateMission {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-left: 2%;
}
.missionOrder .missionOrderContents .contents .left .dateMission p {
  width: 80%;
}
.missionOrder .missionOrderContents .contents .right {
  width: 30%;
}
.missionOrder .missionOrderContents .contents .right h3 {
  color: #1cbcb4;
  margin: 0;
}
.missionOrder .missionOrderContents .contents .right small {
  color: #6c757d;
  font-weight: bold;
  font-size: 1rem;
}
.missionOrder .missionOrderContents .contents .right h4 {
  color: #6c757d;
  font-size: 1rem;
  position: relative;
  padding-left: 5%;
}
.missionOrder .missionOrderContents .contents .right h4::before {
  content: "";
  background-color: #CFCFCF;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 30%;
}

.itemsManagementSection .contents .headerComponent .contents .rowContents {
  background-color: #f8f9fa;
  position: relative;
  padding: 1% 8%;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
  align-items: baseline;
}
.itemsManagementSection .contents .headerComponent .contents .rowContents p {
  color: #1cbcb4;
  font-weight: 500;
}
.itemsManagementSection .contents .headerComponent .contents .rowContents button {
  background-color: #1cbcb4;
  border: unset;
}
.itemsManagementSection .contents .table {
  padding: 4% 2%;
}
.itemsManagementSection .contents .table table thead tr th {
  background-color: #1cbcb4;
  border-color: #1cbcb4;
  color: #f8f9fa;
  text-align: center;
}
.itemsManagementSection .contents .table table tbody tr td {
  border: unset;
  text-align: center;
}
.itemsManagementSection .contents .table table tbody tr td svg {
  cursor: pointer;
}
.itemsManagementSection .contents .table table tbody tr td .btnStatus {
  display: flex;
  justify-content: space-around;
}
.itemsManagementSection .contents .table table tbody tr td .btnStatus button {
  font-weight: 500;
}

.NewItemsManagementSection .contents .topInput {
  display: flex;
  justify-content: space-around;
  padding-top: 2%;
}
.NewItemsManagementSection .contents .topInput .input {
  width: 20%;
}
.NewItemsManagementSection .contents .topInput .input .ant-picker {
  width: 100%;
}
.NewItemsManagementSection .contents .bodyInput {
  display: flex;
}
.NewItemsManagementSection .contents .bodyInput .left {
  width: 60%;
  font-family: "Poppins", sans-serif;
}
.NewItemsManagementSection .contents .bodyInput .left h3 {
  color: #1cbcb4;
  text-align: center;
  font-weight: 500;
  font-size: 1.5rem;
}
.NewItemsManagementSection .contents .bodyInput .left .left_side_input {
  margin-bottom: 2%;
  display: flex;
  justify-content: center;
}
.NewItemsManagementSection .contents .bodyInput .left .left_side_input input {
  width: 60%;
}
.NewItemsManagementSection .contents .bodyInput .right {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
}
.NewItemsManagementSection .contents .bodyInput .right h3 {
  color: #1cbcb4;
  text-align: center;
  font-weight: 500;
  font-size: 1.5rem;
}
.NewItemsManagementSection .contents .bodyInput .right p {
  font-weight: bold;
}
.NewItemsManagementSection .contents .submitBtn {
  display: flex;
  justify-content: center;
}
.NewItemsManagementSection .contents .submitBtn button {
  background-color: #1cbcb4;
  border: unset;
  width: 30%;
}

.dataManagement .headerComponent .contents .rowContents {
  background-color: #f8f9fa;
  position: relative;
  padding: 1% 8%;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
  align-items: baseline;
}
.dataManagement .headerComponent .contents .rowContents p {
  color: #1cbcb4;
  font-weight: 500;
}
.dataManagement .headerComponent .contents .rowContents button {
  background-color: #1cbcb4;
  border: unset;
}
.dataManagement .headerComponent .contents {
  display: block;
}
.dataManagement .contents {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: url("./../img/loginBack.jpg");
  background-position: center;
  background-size: cover;
}
.dataManagement .contents .icones {
  display: flex;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
}
.dataManagement .contents .icones .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.dataManagement .contents .icones .icon:hover svg {
  transform: scale(1.2);
  color: #1cbcb4;
}
.dataManagement .contents .icones .icon:hover p {
  color: #1cbcb4;
}
.dataManagement .contents .icones .icon a svg {
  cursor: pointer;
  height: 100px;
  width: 100px;
  transition: all 0.3s ease-in-out;
  margin-bottom: 4%;
  color: black;
}
.dataManagement .contents .icones .icon p {
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
  transition: all 0.3s ease-in-out;
}

.responsableTableSection .contents .headerComponent .contents .rowContents {
  background-color: #f8f9fa;
  position: relative;
  padding: 1% 8%;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
  align-items: baseline;
}
.responsableTableSection .contents .headerComponent .contents .rowContents p {
  color: #1cbcb4;
  font-weight: 500;
}
.responsableTableSection .contents .headerComponent .contents .rowContents button {
  background-color: #1cbcb4;
  border: unset;
}
.responsableTableSection .contents .table {
  padding: 4% 2%;
}
.responsableTableSection .contents .table table thead tr th {
  background-color: #1cbcb4;
  border-color: #1cbcb4;
  color: #f8f9fa;
  text-align: center;
}
.responsableTableSection .contents .table table tbody tr td {
  border: unset;
  text-align: center;
  width: 100px;
}
.responsableTableSection .contents .table table tbody tr td svg {
  cursor: pointer;
}
.responsableTableSection .contents .table table tbody tr td .btnStatus {
  display: flex;
  justify-content: space-around;
}
.responsableTableSection .contents .table table tbody tr td .btnStatus button {
  font-weight: 500;
}

.floatTableSection .contents .headerComponent .contents .rowContents {
  background-color: #f8f9fa;
  position: relative;
  padding: 1% 8%;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
  align-items: baseline;
}
.floatTableSection .contents .headerComponent .contents .rowContents p {
  color: #1cbcb4;
  font-weight: 500;
}
.floatTableSection .contents .headerComponent .contents .rowContents button {
  background-color: #1cbcb4;
  border: unset;
}
.floatTableSection .contents .table {
  padding: 4% 2%;
}
.floatTableSection .contents .table table thead tr th {
  background-color: #1cbcb4;
  border-color: #1cbcb4;
  color: #f8f9fa;
  text-align: center;
}
.floatTableSection .contents .table table tbody tr td {
  border: unset;
  text-align: center;
  width: 100px;
}
.floatTableSection .contents .table table tbody tr td svg {
  cursor: pointer;
}
.floatTableSection .contents .table table tbody tr td .btnStatus {
  display: flex;
  justify-content: space-around;
}
.floatTableSection .contents .table table tbody tr td .btnStatus button {
  font-weight: 500;
}

.documentTableSection .contents .headerComponent .contents .rowContents {
  background-color: #f8f9fa;
  position: relative;
  padding: 1% 8%;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
  align-items: baseline;
}
.documentTableSection .contents .headerComponent .contents .rowContents p {
  color: #1cbcb4;
  font-weight: 500;
}
.documentTableSection .contents .headerComponent .contents .rowContents button {
  background-color: #1cbcb4;
  border: unset;
}
.documentTableSection .contents .table {
  padding: 4% 2%;
}
.documentTableSection .contents .table table thead tr th {
  background-color: #1cbcb4;
  border-color: #1cbcb4;
  color: #f8f9fa;
  text-align: center;
}
.documentTableSection .contents .table table tbody tr td {
  border: unset;
  text-align: center;
  width: 100px;
}
.documentTableSection .contents .table table tbody tr td svg {
  cursor: pointer;
}
.documentTableSection .contents .table table tbody tr td .btnStatus {
  display: flex;
  justify-content: space-around;
}
.documentTableSection .contents .table table tbody tr td .btnStatus button {
  font-weight: 500;
}

.NewResponsableSection .contents {
  font-size: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.NewResponsableSection .contents .inputContents input, .NewResponsableSection .contents .inputContents .ant-picker, .NewResponsableSection .contents .inputContents .ant-select {
  margin-bottom: 2%;
  width: 100%;
}
.NewResponsableSection .contents .submitBtn {
  display: flex;
  justify-content: center;
}
.NewResponsableSection .contents .submitBtn button {
  background-color: #1cbcb4;
  border: unset;
  width: 30%;
}

.NewFloatSection .contents {
  font-size: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.NewFloatSection .contents .inputContents input, .NewFloatSection .contents .inputContents .ant-picker, .NewFloatSection .contents .inputContents .ant-select {
  margin-bottom: 2%;
  width: 100%;
}
.NewFloatSection .contents .submitBtn {
  display: flex;
  justify-content: center;
}
.NewFloatSection .contents .submitBtn button {
  background-color: #1cbcb4;
  border: unset;
  width: 30%;
}

.NewDocSection .contents {
  font-size: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.NewDocSection .contents .inputContents input, .NewDocSection .contents .inputContents .ant-picker, .NewDocSection .contents .inputContents .ant-select {
  margin-bottom: 2%;
  width: 100%;
}
.NewDocSection .contents .submitBtn {
  display: flex;
  justify-content: center;
}
.NewDocSection .contents .submitBtn button {
  background-color: #1cbcb4;
  border: unset;
  width: 30%;
}

.chargeVariableSection .contents .filter {
  display: flex;
  justify-content: center;
  color: #3A7E8B;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.chargeVariableSection .contents .filter .inputField {
  padding: 3% 1%;
  display: flex;
  flex-direction: column;
  width: 30%;
}
.chargeVariableSection .contents .chargeVariableIcons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  font-family: "Poppins", sans-serif;
}
.chargeVariableSection .contents .chargeVariableIcons .icon {
  width: 18%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.chargeVariableSection .contents .chargeVariableIcons .icon .selected {
  color: #1FC607;
}
.chargeVariableSection .contents .chargeVariableIcons .icon p {
  text-align: center;
  color: #3A7E8B;
  font-weight: 600;
  font-size: 0.8rem;
}
.chargeVariableSection .contents .bottomContents {
  padding: 0% 4%;
  margin-top: 5%;
  font-family: "Poppins", sans-serif;
}
.chargeVariableSection .contents .bottomContents .contents {
  display: flex;
  justify-content: space-between;
}
.chargeVariableSection .contents .bottomContents .contents .leftSide {
  width: 28%;
}
.chargeVariableSection .contents .bottomContents .contents .leftSide .leftSideP {
  height: 50px;
  width: 90%;
  color: #1cbcb4;
  font-size: 1rem;
}
.chargeVariableSection .contents .bottomContents .contents .inputs {
  width: 50%;
  position: relative;
}
.chargeVariableSection .contents .bottomContents .contents .inputs::before {
  content: "";
  position: absolute;
  left: -10%;
  top: 0;
  width: 1px;
  height: 80%;
  background-color: #6c757d;
}
.chargeVariableSection .contents .bottomContents .contents .inputs::after {
  content: "";
  position: absolute;
  right: -10%;
  top: 0;
  width: 1px;
  height: 80%;
  background-color: #6c757d;
}
.chargeVariableSection .contents .bottomContents .contents .inputs .input {
  height: 50px;
  display: grid;
  grid-template-columns: 8fr 1fr;
}
.chargeVariableSection .contents .bottomContents .contents .inputs .input .selectedFileStyle {
  border: 1px solid #1cbcb4;
  height: 30px;
  text-align: center;
  border-radius: 5px;
  color: #1cbcb4;
  cursor: pointer;
}
.chargeVariableSection .contents .bottomContents .contents .inputs .input .notSelectedFileStyle {
  border: 1px solid red;
  height: 30px;
  text-align: center;
  border-radius: 5px;
  color: red;
  cursor: pointer;
}
.chargeVariableSection .contents .bottomContents .contents .inputs .input span {
  color: #6c757d;
  margin-left: 1rem;
  font-weight: 500;
}
.chargeVariableSection .contents .bottomContents .contents .inputs_2_grid_2 {
  grid-template-columns: 1fr 1fr !important;
}
.chargeVariableSection .contents .bottomContents .contents .inputs_2 {
  width: 50%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  margin-top: -2%;
}
.chargeVariableSection .contents .bottomContents .contents .inputs_2::before {
  content: "";
  position: absolute;
  left: -10%;
  top: 8%;
  width: 1px;
  height: 80%;
  background-color: #6c757d;
}
.chargeVariableSection .contents .bottomContents .contents .inputs_2::after {
  content: "";
  position: absolute;
  right: -10%;
  top: 8%;
  width: 1px;
  height: 80%;
  background-color: #6c757d;
}
.chargeVariableSection .contents .bottomContents .contents .inputs_2 .input {
  padding: 0% 2%;
}
.chargeVariableSection .contents .bottomContents .contents .inputs_2 .input input {
  margin-bottom: 2%;
}
.chargeVariableSection .contents .bottomContents .contents .inputs_2 .input label {
  text-align: center;
  width: 100%;
  color: #6c757d;
  font-weight: 500;
}
.chargeVariableSection .contents .bottomContents .contents .inputs_2 .lastInput {
  width: 300%;
}
.chargeVariableSection .contents .bottomContents .contents .rightSide {
  width: 18%;
}
.chargeVariableSection .contents .bottomContents .contents .rightSide .rightSideBtn {
  height: 50px;
  display: flex;
  justify-content: right;
}
.chargeVariableSection .contents .bottomContents .contents .rightSide .rightSideBtn button {
  width: 100px;
  height: 35px;
}

.evaluationSection .headerComponent .contents .rowContents {
  background-color: #f8f9fa;
  position: relative;
  padding: 1% 8%;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
  align-items: baseline;
}
.evaluationSection .headerComponent .contents .rowContents p {
  color: #1cbcb4;
  font-weight: 500;
}
.evaluationSection .headerComponent .contents .rowContents button {
  background-color: #1cbcb4;
  border: unset;
}
.evaluationSection .headerComponent .contents {
  display: block;
}
.evaluationSection .topInputs {
  display: flex;
  justify-content: space-around;
  font-family: "Poppins", sans-serif;
  margin-top: 2%;
}
.evaluationSection .topInputs .inputs {
  width: 22%;
}
.evaluationSection .topInputs .inputs label {
  font-size: 0.8rem;
  text-align: center;
  color: #1cbcb4;
  font-weight: 500;
}
.evaluationSection .evaluationSectionContents {
  margin-top: 4%;
}
.evaluationSection .evaluationSectionContents .leftContents {
  font-family: "Poppins", sans-serif;
  width: 100%;
  margin-top: 8%;
}
.evaluationSection .evaluationSectionContents .leftContents h3 {
  color: #3A7E8B;
  font-weight: 500;
  font-size: 1.5rem;
  position: relative;
  margin-left: 10%;
}
.evaluationSection .evaluationSectionContents .leftContents h3::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  background-color: #3A7E8B;
  left: -3%;
  top: 0;
}
.evaluationSection .evaluationSectionContents .leftContents .cardContents p {
  display: grid;
  grid-template-columns: 2fr 0.5fr 1.5fr 0.5fr;
}
.evaluationSection .evaluationSectionContents .leftContents .cardContents p span {
  color: #1cbcb4;
}
.evaluationSection .evaluationSectionContents .leftContents .cardContents p span:first-of-type {
  font-weight: bold;
}/*# sourceMappingURL=style.css.map */