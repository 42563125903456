.dataManagement {
    .headerComponent {
        @include dataManagementHederStyle;
        .contents {
            display: block;
        }
    }
    .contents {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-image: url('./../img/loginBack.jpg');
        background-position: center;
        background-size: cover;
        .icones {
            display: flex;
            justify-content: space-between;
            font-family: $main_font;
            .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                &:hover {
                    svg {
                        transform: scale(1.2);
                        color: $mainColor;
                    }
                    p {
                        color: $mainColor;
                    }
                }
                a {
                    svg {
                        cursor: pointer;
                        height: 100px;
                        width: 100px;
                        transition: all .3s ease-in-out;
                        margin-bottom: 4%;
                        color: black;
                    }
                }
                p {
                    font-weight: 500;
                    font-size: 1rem;
                    margin: 0;
                    transition: all .3s ease-in-out;
                }
            }
        }
    }
}

.responsableTableSection {
    .contents {
        .headerComponent {
            @include dataManagementHederStyle
        }
        .table {
            @include dataManagementTableStyle
        }
    }
}

.floatTableSection {
    .contents {
        .headerComponent {
            @include dataManagementHederStyle
        }
        .table {
            @include dataManagementTableStyle
        }
    }
}

.documentTableSection {
    .contents {
        .headerComponent {
            @include dataManagementHederStyle
        }
        .table {
            @include dataManagementTableStyle
        }
    }
}

.NewResponsableSection {
    .contents {
        @include newOperationStyle
    }
}

.NewFloatSection {
    .contents {
        @include newOperationStyle
    }
}

.NewDocSection {
    .contents {
        @include newOperationStyle
    }
}

.chargeVariableSection {
    .contents {
        .filter {
            display: flex;
            justify-content: center;
            color: $mainColor2;
            font-weight: 600;
            font-family: $main_font;
            .inputField {
                padding: 3% 1%;
                display: flex;
                flex-direction: column;
                width: 30%;
            }
        }
        .chargeVariableIcons {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            font-family: $main_font;
            .icon {
                width: 18%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                .selected {
                    color: #1FC607;
                }
                p {
                    text-align: center;
                    color: $mainColor2;
                    font-weight: 600;
                    font-size: .8rem;
                }
            }
        }
        .bottomContents {
            padding: 0% 4%;
            margin-top: 5%;
            font-family: $main_font;
            .contents {
                display: flex;
                justify-content: space-between;
                .leftSide {
                    width: 28%;
                    .leftSideP {
                        height: 50px;
                        width: 90%;
                        color: $mainColor;
                        font-size: 1rem;
                    }
                }
                .inputs {
                    width: 50%;
                    position: relative;
                    &::before {
                        content: "";
                        position: absolute;
                        left: -10%;
                        top: 0;
                        width: 1px;
                        height: 80%;
                        background-color: $Color_Gri;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        right: -10%;
                        top: 0;
                        width: 1px;
                        height: 80%;
                        background-color: $Color_Gri;
                    }
                    .input {
                        height: 50px;
                        display: grid;
                        grid-template-columns: 8fr 1fr;
                        .selectedFileStyle {
                            border: 1px solid $mainColor;
                            height: 30px;
                            text-align: center;
                            border-radius: 5px;
                            color: $mainColor;
                            cursor: pointer;
                        }
                        .notSelectedFileStyle {
                            border: 1px solid red;
                            height: 30px;
                            text-align: center;
                            border-radius: 5px;
                            color: red;
                            cursor: pointer;
                        }
                        span {
                            color: $Color_Gri;
                            margin-left: 1rem;
                            font-weight: 500;
                        }
                    }
                }
                .inputs_2_grid_2 {
                    grid-template-columns: 1fr 1fr !important;
                }
                .inputs_2 {
                    width: 50%;
                    position: relative;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    justify-content: space-between;
                    margin-top: -2%;
                    &::before {
                        content: "";
                        position: absolute;
                        left: -10%;
                        top: 8%;
                        width: 1px;
                        height: 80%;
                        background-color: $Color_Gri;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        right: -10%;
                        top: 8%;
                        width: 1px;
                        height: 80%;
                        background-color: $Color_Gri;
                    }
                    .input {
                        padding: 0% 2%;
                        // &:last-of-type {
                        //     width: 300%;
                        // }
                        input {
                            margin-bottom: 2%;
                        }
                        label {
                            text-align: center;
                            width: 100%;
                            color: $Color_Gri;
                            font-weight: 500;
                        }
                    }
                    .lastInput {
                        width: 300%;
                    }
                }
                .rightSide {
                    width: 18%;
                    .rightSideBtn {
                        height: 50px;
                        display: flex;
                        justify-content: right;
                        button {
                            width: 100px;
                            height: 35px;
                        }
                    }
                }
            }
        }
    }
}
