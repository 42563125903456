.login_section {
    background-image: url('./../img/loginBack.jpg');
    background-position: center;
    background-size: cover;
    height: 100vh;
    .contents {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;

        font-family: $main_font;
        img {
            width: 8%;
        }
        h1 {
            text-align: center;
            color: $Color_Gri;
            font-size: 1rem;
        }
        h2 {
            text-align: center;
            color: $mainColor;
            font-size: 1.2rem;
            font-weight: 600;
            @include Large {
                margin-top: 5rem
            }
        }
        form {
            width: 60%;
            @include Large {
                width: 80%;
            }
            .submitButton {
                background-color: $mainColor;
                border: unset;
                width: 30%;
                margin-left: 35%;
                margin-right: auto;
                @include Large {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }
}
